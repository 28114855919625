.container {
  display: flex;
  text-align: center;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
  background: linear-gradient(90deg, #422b79 0%, #a88ceb 100%);
  border-radius: 24px;
  margin-right: px;
  border-radius: 4px;
  padding-top: 8.2px;
  margin-top: 7.5px;
  cursor: pointer;
  margin-left: 14px;
}

a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;

  background: linear-gradient(90deg, #422b79 0%, #a88ceb 100%);
  border-radius: 24px;
  margin-right: px;
  padding-top: 8.2px;
  cursor: pointer;
}

span {
  z-index: 5;
  position: relative;
  transition: color 350ms ease;
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #fcfcfd;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 2.4%;
}
