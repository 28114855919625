#footer-contact {
  font-family: Avenir;
  font-style: normal;
  font-weight: 300;
  font-size: 14.5px;
  line-height: 144.5%;
}

@media only screen and (max-width: 600px) {
  #footer-contact {
    font-size: 12px;
    width: 96%;
  }
}
