.tickers {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 100%;
  color: hsl(257, 44%, 32%);
  margin: 0;
  text-align: left;
}
@media (max-width: 768px) {
  .tickers {
    text-align: center;
  }
}
.main {
  position: relative;
  width: calc(100%);
  height: 500px;
  margin-bottom: 0%;
}

.circle {
  position: absolute;
}

.circle:nth-child(1) {
  top: 5%;
  left: calc(15%);
}

.circle:nth-child(2) {
  top: 5%;
  left: 56%;
}

.circle:nth-child(3) {
  top: 35%;
  left: 0%;
}

.circle:nth-child(4) {
  top: 37%;
  left: 43%;
}

.circle:nth-child(5) {
  top: 35%;
  left: 65%;
}
.circle:nth-child(6) {
  /* transform: rotate(310deg) translateX(250px); */
  top: 70%;
  left: 9%;
}
.circle:nth-child(7) {
  top: 70%;
  left: 56%;
}

@media (max-width: 1000px) {
  .circle:nth-child(3) {
    top: 35%;
    left: 5%;
  }
  .circle:nth-child(6) {
    /* transform: rotate(310deg) translateX(250px); */
    top: 70%;
    left: 5%;
  }
}
